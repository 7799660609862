import React from 'react'
import styled from 'styled-components'
import {graphql, Link} from 'gatsby'

import Layout from '../containers/layout'
import SEO from '../components/seo'
import {colors, font, fontfamily} from '../styles/variables'
import Container from '../components/container'

const Title = styled.h1`
  font-size: 3.6rem;
  text-align: center;
  margin: 0 auto;
  display: block;
  padding: 5.6rem 0;
  font-family: ${fontfamily.jaRounded};
  color: ${colors.lightBlack};
`
const Text = styled.div`
  margin: 0 2.4rem 4rem 2.4rem;
  font-family: ${fontfamily.ja};
  font-size: 1.6rem;
  text-align: center;
  color: ${colors.lightBlack};
`

const StyledLink = styled(props => <Link {...props} />)`
  color: ${colors.blue};
  font-size: 1.8rem;
  text-align: center;
  margin: 0 auto;
  display: block;
`

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query _404PageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      heroImage{
        ...SanityImage
        alt
      }
      title
      subTitle
      _rawIntro
      _rawFooter
      description
      keywords
    }
  }
`

const NotFoundPage = props => {
  const {data, errors} = props
  const site = (data || {}).site
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title='404: Not found' />
      <Container>
        <Title>NOT FOUND</Title>
        <Text>お探しのページは見つかりませんでした。<br />下記のリンクからトップページへお戻りください。</Text>
        <StyledLink to='/'>トップページに戻る</StyledLink>
      </Container>

    </Layout>
  )
}

export default NotFoundPage
